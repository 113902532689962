import React from 'react';
import {render} from 'react-dom';

import {PortfolioPage} from './pages/PortfolioPage'
import {ContactPage} from './pages/ContactPage'
import Router from './components/routing/TransitionRouterApp'

const pages = {
	home:
		<ContactPage/>,
	portfolio :
		<PortfolioPage/>
}

let landingPage;
let path = window.location.pathname.substring(1)

if(path.trim() === ''){
	landingPage = 'home'
}
else if(Object.keys(pages).includes(path.trim())){
	landingPage = path.trim()
}
else{
	landingPage = 'home'
}

console.log(landingPage)

render(
		<Router landingPage={landingPage} pages={pages}/>,
  window.document.getElementById('app')
);
