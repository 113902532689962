import React from 'react';
import {animated} from 'react-spring';

export default (props) => props.native ?
  <animated.div style={{
      position: props.className ? null : 'absolute',
      width: props.width || '100%',
      height: props.height || '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems:'center',
      ...props.style
    }}
      className={props.className}
      id={props.id}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      >
    {props.children}
  </animated.div>
  :
  <div style={{
      position: props.className ? null : 'absolute',
      width: props.width || '100%',
      height: props.height || '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems:'center',
      ...props.style}}
      className={props.className}
      id={props.id}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      >
    {props.children}
  </div>
