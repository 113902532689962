import React from 'react'
import {useState, useEffect} from 'react'
import {isMobile} from 'react-device-detect'
import Container from '../components/Container'
import firebase from 'firebase/app'
import 'firebase/storage'

var firebaseConfig = {
    apiKey: "AIzaSyBVDE0JEKyZBhhjeCoxo3tOj-FFaE5Kzrc",
    authDomain: "elan-canfield-prod.firebaseapp.com",
    databaseURL: "https://elan-canfield-prod.firebaseio.com",
    projectId: "elan-canfield-prod",
    storageBucket: "elan-canfield-prod.appspot.com",
    messagingSenderId: "265262536210",
    appId: "1:265262536210:web:cc4db1c6d0d8c452be6acb"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  const storage = firebase.storage()


function HoverButton(props){
  const [hovered, setHovered] = useState(null)

  return(
    <Container
      onClick={props.onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{transition: 'all 250ms ease-out', height: isMobile ? '70px' : '40px', fontSize: isMobile ? '48px' : '35px', position: 'relative', opacity: hovered ? 1: 0.3, justifyContent: 'flex-start', color: '#111111'}}>
      {props.children}
    </Container>
  )
}

function ContactPage(props){
  
  const [resumeLink, setResumeLink] = useState(null)

  useEffect(() => {
    if(!resumeLink){
      storage.ref('files/ElanCanfieldResume2019.pdf').getDownloadURL().then(url => {
        setResumeLink(url)
      })
      .catch(err => {
        console.log(err)
      })
    }
  }, [resumeLink])

  return(
    <Container style={{flexFlow: 'column', fontFamily: 'Raleway'}}>
      <Container style={{height: isMobile ? '390px' : '200px', width: isMobile ? '450px' : '280px', fontSize: isMobile ?  '32px' : '24px', flexFlow: 'column', justifyContent: 'flex-start', color: 'black', alignItems:'flex-end'}}>
        <Container style={{height: isMobile ? '72px' : '42px', fontSize: isMobile ? '52px' : '36px', position: 'relative', justifyContent: 'flex-start', color: 'black'}}>
        ELAN CANFIELD
        </Container>
        <HoverButton onClick={() => window.open(resumeLink, '_blank')}>
          RESUME&#9660;
        </HoverButton>
        <HoverButton onClick={() => window.open("https://www.linkedin.com/in/elan-canfield-82321211b/", '_blank')}>
          LINKEDIN&nbsp;<img src="images/linkedin.png" alt="linkedin" style={{height: '36px'}}/>
        </HoverButton>
        <HoverButton onClick={() => window.open("https://www.instagram.com/tofeezy/", '_blank')}>
          INSTAGRAM&nbsp;<img src="images/instagram.png" alt="instagram" style={{height:'40px'}}/>
        </HoverButton>
      </Container>
    </Container>
  )
}

export {ContactPage}
