import React from 'react'
import {useState, useEffect} from 'react'
import MobileSwipeMenu from '../menus/MobileSwipeMenu';
import DesktopStackMenu from '../menus/DesktopStackMenu';
import {isMobile} from 'react-device-detect';
import Container from '../Container';

let showStyle = {
  position: 'absolute',
  bottom: isMobile ? '0%' : null,
  height: isMobile ? '100%' : null,
  opacity: 1,
  zIndex: 1,
  transition: 'all 250ms ease-out'
}

let hideStyle = {
  position: 'absolute',
  bottom: isMobile ? '25%' : null,
  height: isMobile ? '75%' : null,
  opacity: 0,
  zIndex: -1,
  transition: 'all 150ms ease-in'
}



function TransitionRouterApp(props){
    const [selected, setSelected] = useState(props.landingPage)
    const [showMenu, setShowMenu] = useState(true);

    useEffect(()=> {
      window.history.replaceState({}, selected, selected);
    })

    let pageNames = Object.keys(props.pages);

    return (
      <Container>
        {pageNames.map((pageName) =>
          <Container
          key={pageName + '-page'}
          style={
            pageName === selected ?
            showStyle : hideStyle
          }>
            {React.cloneElement(props.pages[pageName], {hideMenu: () => setShowMenu(false)})}
          </Container>
        )}
        {isMobile ?
          <MobileSwipeMenu color="#111111" pageNames={pageNames} selected={selected} selectPage={setSelected} show={showMenu}/>
          :
          <DesktopStackMenu color="#111111" pageNames={pageNames} selected={selected} selectPage={setSelected} show={showMenu}/>
        }

      </Container>
    )
}


export default TransitionRouterApp;
