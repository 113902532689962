import React from 'react';

function MobileSwipeMenuElement(props){

  return(
    <svg
    onClick={props.onClick}
    viewBox='-75 -50 150 100'
    className={props.selected ? 'page-dot selected' : 'page-dot'}
    >
      <ellipse cx='0' cy='15' rx='8' ry='8' className={props.className} fill="#111111"/>
        <text
          key={props.pageName+'menu-element'}
          lengthAdjust="spacing"
          textLength='200'
          style={{
            fontSize: '30px',
          }}
          x='-100' y='-15'
          fill='#111111'
          className={props.selected ? 'menu-element-hovered' : 'menu-element'}
          >
          {props.pageName.toUpperCase()}
        </text>
    </svg>
  )
}

export default MobileSwipeMenuElement;
