import React from 'react';
import {useState, useEffect} from 'react'
import Container from '../Container';
import clickDrag from 'react-clickdrag';
import MobileSwipeMenuElement from './MobileSwipeMenuElement';

function useDragState(props) {
  const [dragState, setDragState] = useState(0)
  const selectedIndex = props.pageNames.indexOf(props.selected)
  const dataDrag = props.dataDrag

  const handleSwipe = (deltaX) => {
    if(deltaX < -300){
      let newIndex = Math.min(selectedIndex + 1, props.pageNames.length - 1);
      let newPageName = props.pageNames[newIndex]
      props.selectPage(newPageName);
      return;
    }
    if(deltaX > 300){
      let newIndex = Math.max(selectedIndex - 1, 0);
      let newPageName = props.pageNames[newIndex]
      props.selectPage(newPageName);
      return;
    }
  }

  useEffect(() => {
    switch(dragState){
      case 0:
        if(dataDrag.isMoving && dataDrag.isMouseDown) {
          setDragState(1)
        }
        break;
      case 1:
        if(!dataDrag.isMouseDown){
          handleSwipe(dataDrag.moveDeltaX);
          setDragState(0)
        }
        break;
      default:
        break;
    }
  })
  return
}

function MobileSwipeMenu(props) {
  //drag state
  // 0 - not dragging
  // 1 - dragging
  // 2 - finished dragging
    useDragState(props)

    return (
      <Container
        className={props.show ?
          'mobile-swipe-menu-container' : 'mobile-swipe-menu-container-hidden'
        }
        style={{
          bottom: '0px',
          position:'fixed',
          alignItems: 'flex-end',
          width: '100vw',
          height: '100px'
        }}>
          {props.pageNames.map((pageName, i) => (
            <MobileSwipeMenuElement
              key={pageName + '-slide-' + i}
              onClick={() => props.selectPage(pageName)}
              selected={pageName===props.selected}
              pageName={pageName}/>
            ))
          }
      </Container>
    )
}

export default clickDrag(MobileSwipeMenu, {touch: true});
