import React from 'react';
import Container from '../Container';
import DesktopStackMenuElement from '../menus/DesktopStackMenuElement'

class  DesktopStackMenu extends React.Component {
  //drag state
  // 0 - not dragging
  // 1 - dragging
  // 2 - finished dragging

  getIndexFromPageName = (pageName) => {
    let pageNames = Object.keys(this.props.router.pages);
    return pageNames.indexOf(pageName);
  }

  getPageNameFromIndex = (index) =>{
    let pageNames = Object.keys(this.props.pages);
    return pageNames[index];
  }


  render() {
    return (
      <Container
        className={this.props.show ?
          'mobile-swipe-menu-container' : 'mobile-swipe-menu-container-hidden'
        }
        style={{
          position:'fixed',
          flexFlow: 'column',
          width: '150px',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
          right: '0px',
          height: 'auto',
          top: '50px',
          fontFamily: 'Raleway',
          zIndex: '2'
        }}>
        {this.props.pageNames.map((pageName, i) => (
          <DesktopStackMenuElement
            key={pageName + '-menu-element-' + i}
            color={this.props.color}
            onClick={() => this.props.selectPage(pageName)}
            selected={pageName===this.props.selected}
            pageName={pageName}/>
          ))
        }
      </Container>
    );
  }
}

export default DesktopStackMenu;
