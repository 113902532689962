import React from 'react';
import {useState} from 'react'

function DesktopStackMenuElement(props){
  const [hovered, setHovered] = useState(false)

  return(
    <svg
    onClick={props.onClick}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
    viewBox='-120 -30 240 60'
    style={{height: '60px', width: '250px', left: '0px'}}
    >
      <rect
        x='50'
        y='-30'
        width='8'
        height='40'
        className={props.className}
        stroke={props.color || '#111111'}
        fill={props.selected ? (props.color || '#111111') : 'none'}
      />
        <text
          key={props.pageName+'menu-element'}
          lengthAdjust="spacing"
          textLength='150'
          x='-125' y='-2'
          fill={props.color || '#111111'}
          className={hovered || props.selected ? 'page-block-selected' : 'page-block'}
          >
          {props.pageName.toUpperCase().replace(/_/, " ")}
        </text>
    </svg>
  )
}

export default DesktopStackMenuElement;
