import React from 'react'
import {useState, useEffect, useCallback} from 'react'
import {isMobile} from 'react-device-detect'
import Container from '../components/Container'

import useDocDimensions from '../hooks/useDocDimensions'

const projects = {
  'Boeckeler Instruments': {
    year: 2019,
    images : ['1.png', '2.png', '3.png'],
    url: 'https://boeckeler.com'
  },
  'BLX Skate': {
    year: 2018,
    images : ['1.png', '2.png'],
    url: 'https://blxskate.com'
  },
  'allonemfg': {
    year: 2019,
    images : ['1.png', '2.png', '3.png'],
    url: 'https://allonemfg.com'
  },
  'LA Monsters FC' : {
    year: 2019,
    images: ['1.png'],
    url: 'https://lamonstersfc.com'
  }
}


function ProjectDisplay(props){
  const [selected, setSelected] = useState(null)
  const [hovered, setHovered] = useState(null)

  useEffect(() => {
    if(selected !== null){
      
    }
  })

  let {width, height} = useDocDimensions()
  //width = isMobile ? width : Math.max(width*0.6, 500)
  //height = isMobile ? height*0.6 : height*0.8

  const handleClick = useCallback(name => {
    if(isMobile){
      if(name === selected){
        window.open(props.projects[name].url, 'blank')
      }
      else{
        setSelected(name)
      }
    }
    else{
      setSelected(name)
      window.open(props.projects[name].url, 'blank')
    }
  }, [selected])

  const projectNames = Object.keys(props.projects)
  const imageFolder = 'images/portfolio'
  const desktopWidth = width*0.8
  const mobileHeight = height - 250
  return(
    <Container style={{height, width, flexFlow: 'column', justifyContent: isMobile ? 'flex-start' : 'center'}}>
      <Container style={{height: '100px', width, position: 'relative', fontSize: '52px', fontWeight: '300'}}>
        {hovered ? hovered.toUpperCase() : null}
      </Container>
      <Container style={{height: isMobile ? mobileHeight : height - 300, width, position: 'relative', flexFlow:'row'}}>
      {
        projectNames.map(name =>
            <Container
              key={name}
              onMouseEnter={() => setHovered(name)}
              onMouseLeave={() =>setHovered(null)}
              onClick={() => handleClick(name)}
              style={{transition: 'all 250ms ease-out', width: desktopWidth/projectNames.length, height:  '100%', position: 'relative', overflow: 'hidden', background: '#efefef'}}>
              <img
                src={`${imageFolder}/${name.toLowerCase().replace(/ /g, "_")}/${props.projects[name].images[0]}`}
                onContextMenu={(e) => e.preventDefault()}
                alt={name}
                style={{
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'all 300ms ease-out',
                  filter: hovered === name ? `brightness(100%)` : 'brightness(30%)'
                }}
                />
            </Container>
        )
      }
      </Container>
    </Container>
  )
}

function PortfolioPage(props){
  return(
    <Container style={{fontFamily: 'Raleway'}}>
        <ProjectDisplay hideMenu={props.hideMenu} projects={projects}/>
    </Container>
  )
}

export {PortfolioPage}
